<template>
    <div class="col col-12 pa-0 v-file-input">
        <div id="appTopol" ref="topol" style="height: 100vh;"></div>
        <MediathequeV2DialogComponent v-model="fileUrl" :openDialogMediatheque="dialogMediatheque" @close="closeDialogMediatheque" :prefilters="prefilters" :availableFilters="availableFilters" :upload="true" :returnUrl="true" />
        <v-dialog class="rounded-lg" persistent width="700" v-model="deleteConfirmDialog">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" >
                    <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                        <div>
                            <v-toolbar-title>{{$t('deleteBlockConfirmation')}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="deleteConfirmDialog = false">{{$t('cancel')}}</v-btn>
                            <v-btn outlined dark depressed @click="confirmDeleteAction" class="ml-3">{{$t('confirm')}}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text class="mt-4">
                    {{ $t('Areyousuretodeleteblock') }}
                </v-card-text>
            </v-card>
        </v-dialog>
        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
    </div>
</template>

<script>
import validate from "../functions/validations";
import MediathequeV2DialogComponent from '@/components/dialogs/MediathequeV2DialogComponent';
import GenericDataService from '@/services/GenericDataService';
import getForm from "@/mixins/mixins";

// Plugin Settings qui bougeront et qui devront etre dynamique (pour les merge tags par exemple : définition des variables)
const TOPOL_OPTIONS = {
    id: "#appTopol",
    authorize: {
        apiKey: "aENJaw1tAld461ePzU9MgcFZeU0gjgagt6GlK13N9ZYqXNdVyIR24z3FFulT",
        userId: "UserID",
    },
    language: "fr",
    savedBlocks: [],
    theme: {
        preset: 'light',
        colors: {
            primary: '#000',
            active: '#000',
            "c-success": '#09577d',
            "success-light": '#09577d',
            "active-light": '#000',
        }
    },
    tinyConfig:{
        toolbar_location:'top'
    },
    customFileManager: true,
    mergeTags: [],
    // smartMergeTags: {
    //     enabled: true,
    //     syntax: {
    //         start: "{{",
    //         end: "}}"
    //     }
    // },
    disableAiAssistant: true,
    topBarOptions: ["undoRedo", "changePreview", "previewSize"],
    premadeBlocks: [{
        name: 'Liens',
        blocks: [
            {
                name: "Lien de désinscription",
                definition:[
                    {
                        "tagName": "mj-section",
                        "attributes": {
                            "full-width": false,
                            "padding": "9px 0px 9px 0px"
                        },
                        "children": [
                            {
                                "tagName": "mj-column",
                                "attributes": {
                                    "width": "100%",
                                    "vertical-align": "top"
                                },
                                "children": [
                                    {
                                        "tagName": "mj-text",
                                        "attributes": {
                                            "align": "left",
                                            "padding": "15px 15px 15px 15px",
                                            "line-height": 1.5,
                                            "containerWidth": 600
                                        },
                                        "uid": "-aUZXKZ35",
                                        "content": "<p style=\"text-align: center;\" id=\"unsubscribeLink\"><span style=\"text-decoration: underline;\"><a href=\"{{unsubscribe.link}}\" target=\"_blank\" rel=\"noopener\">Se désinscrire<\/a><\/span><\/p>"
                                    }
                                ],
                                "uid": "HJQ8ytZzW"
                            }
                        ],
                        "layout": 1,
                        "backgroundColor": null,
                        "backgroundImage": null,
                        "paddingTop": 0,
                        "paddingBottom": 0,
                        "paddingLeft": 0,
                        "paddingRight": 0,
                        "uid": "Byggju-zb"
                    }
                ]
            },
            {
                name: "Lien de visualisation en ligne",
                definition:[
                    {
                        "tagName": "mj-section",
                        "attributes": {
                            "full-width": false,
                            "padding": "9px 0px 9px 0px"
                        },
                        "children": [
                            {
                                "tagName": "mj-column",
                                "attributes": {
                                    "width": "100%",
                                    "vertical-align": "top"
                                },
                                "children": [
                                    {
                                        "tagName": "mj-text",
                                        "attributes": {
                                            "align": "left",
                                            "padding": "15px 15px 15px 15px",
                                            "line-height": 1.5,
                                            "containerWidth": 600
                                        },
                                        "uid": "-aUZXKZ36",
                                        "content": "<p style=\"text-align: center;\" id=\"viewingLink\"><span style=\"text-decoration: underline;\"><a href=\"%%VIEWINGLINK%%\" target=\"_blank\" rel=\"noopener\">Voir la version en ligne<\/a><\/span><\/p>"
                                    }
                                ],
                                "uid": "HJQ8ytZzX"
                            }
                        ],
                        "layout": 1,
                        "backgroundColor": null,
                        "backgroundImage": null,
                        "paddingTop": 0,
                        "paddingBottom": 0,
                        "paddingLeft": 0,
                        "paddingRight": 0,
                        "uid": "Byggju-zd"
                    }
                ]
            }
        ]
    }],
    callbacks: {}
};

export default {
    components: { },
    props: ["model", "fieldName", "field"],
    components:{ MediathequeV2DialogComponent, FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"), },
    mixins: [validate, getForm],
    data() {
        return {
            dialogMediatheque: false,
            fileUrl: "",
            prefilters: {
                "utilisation": "template_email"
            },
            availableFilters: [],
            deleteConfirmDialog: false,
            deleteId: 0,
            customBlocks: [],
            autosave: true,
            form: null,
        }
    },
    mounted() {
        let vm = this;
        
        GenericDataService.getData('/template/getCustomBlocks').then((response) => {
            this.customBlocks = response.data.data
            TOPOL_OPTIONS.savedBlocks = response.data.data 
        })
        GenericDataService.getData("/template/getAvailableVariables").then((response) => {
            TOPOL_OPTIONS.mergeTags = this.tradIfNeeded(response.data.data);
            
            TOPOL_OPTIONS.callbacks['onOpenFileManager'] = function() {
                vm.dialogMediatheque = true;
            };

            TOPOL_OPTIONS.apiAuthorizationHeader = {
                'Token' : vm.apiAccessToken
            };

            TOPOL_OPTIONS.callbacks['onInit'] = function() {
                let json = vm.model[vm.fieldName] && vm.model[vm.fieldName].json ? vm.model[vm.fieldName].json : false;
                if(json)
                    TopolPlugin.load(JSON.stringify(json));
                
                setInterval(function() {
                    vm.saveIframe();
                }, 60000);
            };

            TOPOL_OPTIONS.callbacks['onSave'] = function(json, html) {
                vm.saveTemplate(json, html);
            };

            TOPOL_OPTIONS.callbacks['onBlockSave'] = function(json) {
                vm.editCustomBlock(null, json);
            };
            TOPOL_OPTIONS.callbacks['onBlockRemove'] = function(id) {
                let block = vm.customBlocks.find(block => block.id == id);
                if(block.canUpdate)
                    vm.deleteCustomBlock(id);
                else
                    vm.$store.dispatch('ADD_ERROR', { errorMessage : "cantDeleteCustomBlock", type: "user" });
            };
            TOPOL_OPTIONS.callbacks['onBlockEdit'] = function(id) {
                let block = vm.customBlocks.find(block => block.id == id);
                if(block.canUpdate)
                    vm.editCustomBlock(id);
                else
                    vm.$store.dispatch('ADD_ERROR', { errorMessage : "cantEditCustomBlock", type: "user" });
            };
            if(this.$store.state.auth.currentUser.accountParameters && this.$store.state.auth.currentUser.accountParameters.rcu){
                TOPOL_OPTIONS.api = {
                    PRODUCTS: this.$store.state.auth.apiUrl + "/entity/getStockForTOPOL?token=" + this.$store.state.auth.idToken,
                    FEEDS: this.$store.state.auth.apiUrl + "/entity/getStockFeed?token=" + this.$store.state.auth.idToken,
                    IMAGE_EDITOR_UPLOAD: this.$store.state.auth.apiUrl + "/mediatheque/uploadImageForEmail?account_id="+this.$store.state.auth.currentUser.accountParameters.id,
                    IMAGE_UPLOAD: this.$store.state.auth.apiUrl + "/mediatheque/uploadImageForEmailDrop?account_id="+this.$store.state.auth.currentUser.accountParameters.id,
                }
            } else {
                TOPOL_OPTIONS.api = {
                    IMAGE_EDITOR_UPLOAD: this.$store.state.auth.apiUrl + "/mediatheque/uploadImageForEmail?account_id="+this.$store.state.auth.currentUser.accountParameters.id,
                    IMAGE_UPLOAD: this.$store.state.auth.apiUrl + "/mediatheque/uploadImageForEmailDrop?account_id="+this.$store.state.auth.currentUser.accountParameters.id,
                }
            }
            
            TopolPlugin.init(TOPOL_OPTIONS);
        });
    },
    destroyed() {
        TopolPlugin.destroy()
    },
    watch: {
        fileUrl (newFileUrl) {
            TopolPlugin.chooseFile(newFileUrl);
        }
    },
    computed: {
        apiAccessToken () {
            return this.$store.state.auth.accessToken;
        }
    },
    methods: {
        customRefresh() {
            GenericDataService.getData('/template/getCustomBlocks').then((response) => {
                this.customBlocks = response.data.data;
                TopolPlugin.setSavedBlocks(response.data.data);
            });
        },
        deleteCustomBlock(id){
            this.deleteId = id
            this.deleteConfirmDialog = true
        },
        confirmDeleteAction(){
            GenericDataService.postData('template/deleteCustomBlock?id='+this.deleteId).then((response) => {
                GenericDataService.getData('/template/getCustomBlocks').then((response) => {
                    this.customBlocks = response.data.data
                    TopolPlugin.setSavedBlocks(response.data.data);
                    this.deleteConfirmDialog = false
                })
            })
        },
        editCustomBlock(id = null, json = null) {
            let url = "/template/getCustomBlockForm";

            if(id != null)
                url += "?id=" + id;
            else if(json != null)
                url += "?definition=" + encodeURIComponent(JSON.stringify(json.definition));

            this.getForm(url);
        },
        tradIfNeeded(data){
            data.forEach(element => {
                if(element.to_trad){
                    if(element.hasOwnProperty('name'))
                        element.name = this.$t(element.name)
                    else
                        element.text = this.$t(element.text)
                }

                if(element.hasOwnProperty('items'))
                   element.items = this.tradIfNeeded(element.items)
            });
            return data
        },
        closeDialogMediatheque: function(){
            this.dialogMediatheque = false
        },
        saveIframe: function(autosave = true) {
            this.autosave = autosave;
            TopolPlugin.save();
        },
        saveTemplate: function(json, html) {
            this.model[this.fieldName] = { json: json, html: html };
            if(this.autosave)
                this.$emit('saveOnlyTemplateEmail');
            else
                this.$emit('validateForm', true);
        },
    },
};
</script>

<style lang="scss">
</style>
