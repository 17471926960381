<template>
    <div :class="'input-form libField'+ (field.inCampaign ? ' inCampaign' : '') + (fromStepper ? ' inStepper' : '')" @mouseover.stop="hover = true" @mouseleave.stop="hover = false">
        <div v-if="hover && field.inCampaign && !fromStepper" :id="'thumbHover_'+key" class="hoverThumb d-flex flex-column align-center justify-center cursorPointer" @click="openPreview">
            <!-- <v-btn v-if="from === 'customLibrarySelect'" depressed color="pink" dark class="mb-4" @click="$emit('unselectModel', model)">{{ $t('unselect_model') }}</v-btn> -->
            <v-btn  small color="primary" v-if="model[fieldName] && model[fieldName].hasOwnProperty(idProperty) && model[fieldName][idProperty]" dark class="mb-2 mr-2" @click="openPreview">{{ $t('preview') }}</v-btn>
        </div>

        <div v-if="hover && (!field.inCampaign || (field.inCampaign && fromStepper))" :id="'thumbHover_'+key" class="hoverThumb d-flex flex-column align-center justify-center" >
            <!-- <v-btn v-if="from === 'customLibrarySelect'" depressed color="pink" dark class="mb-4" @click="$emit('unselectModel', model)">{{ $t('unselect_model') }}</v-btn> -->
            <v-btn  small color="pink" v-if="!field.campaign || !field.campaign.status || (field.campaign.status != 'PROGRAMMED' && field.campaign.status != 'COMPLETED' && field.campaign.status != 'CANCELED' && field.campaign.status != 'PREPARATION' && field.campaign.status != 'ON_GOING')" dark class="mb-2 mr-2" @click="openLib">{{ (!model[fieldName] || !model[fieldName].hasOwnProperty(idProperty) || !model[fieldName][idProperty]) ? $t('selectTemplate') : $t('changeTemplate') }}</v-btn>
            <v-btn  small color="primary" v-if="model[fieldName] && model[fieldName].hasOwnProperty(idProperty) && model[fieldName][idProperty]" dark class="mb-2 mr-2" @click="openPreview">{{ $t('preview') }}</v-btn>
            <v-btn  small color="primary" v-if="(!field.campaign || !field.campaign.status || (field.campaign.status != 'PROGRAMMED' && field.campaign.status != 'COMPLETED' && field.campaign.status != 'CANCELED' && field.campaign.status != 'PREPARATION' && field.campaign.status != 'ON_GOING')) && (model[fieldName] && (field.campaign && field.campaign.status == 'WORKFLOW' ||( model[fieldName].hasOwnProperty(idProperty)  && model[fieldName].template)))" dark class="mb-0 mr-2" @click="editTemplate">{{ $t('edit') }}</v-btn>
        </div>

        <span v-if="field.title" class="label black--text">
            <b>{{$t(field.title)}}</b>
            <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
            <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
        </span>
            
        <div v-if="fromStepper">
            <div :class="'create itemTemplateSelect '" v-if="!model[fieldName] || !model[fieldName].hasOwnProperty(idProperty) || !model[fieldName][idProperty]" @click="openLib">
                <v-chip class="chipType" color="pink" dark small>{{ field.libType }}</v-chip>
                <div>
                    <v-chip  color="white" class="primary--text">{{ $t("noTemplateSelected") }}</v-chip>
                    <v-input v-model="model[fieldName]" type="hidden" ref="inputLayout" :rules=[validate] class=""></v-input>
                </div>
                <div class="itemName">
                    <b>{{ model[fieldName].name }}</b>
                    <p class="mt-0" style="font-weight:normal;" v-if="model[fieldName].description && model[fieldName].description.date_start">{{ parseFullDateToFr(model[fieldName].description.date_start) }}</p>
                </div>
            </div>
            <div v-else :class="'itemTemplateSelect ' + field.libType" @click="openLib">
                <v-chip class="chipType" color="pink" dark small>{{ field.libType }}</v-chip>
                    <div style="height:100%;">
                        <div v-if="model[fieldName].template_compiled && model[fieldName].template_compiled.hasOwnProperty('mp3') && model[fieldName].template_compiled.mp3" class="d-flex align-center justify-center ma-6">
                            <div  style="width:50%;">
                                <span><strong>{{$t('title')}}</strong> : {{model[fieldName].template_compiled.mp3.title}}</span>
                                <audio v-if="model[fieldName].template_compiled.mp3 && model[fieldName].template_compiled.mp3.url" controls controlsList="nodownload" :src="model[fieldName].template_compiled.mp3.url" style="width: 100%;"></audio>
                            </div>
                        </div>
                        <v-card v-else max-width="600" style="margin-left:auto; margin-right:auto; height:calc(100% - 0px);">
                            <iframe v-if="model[fieldName].template_compiled && model[fieldName].template_compiled.html" scrolling="no" id="templateIframeHtml" :srcdoc="model[fieldName].template_compiled.html" @load="resizeIframe('templateIframeHtml')" style="padding:5px;height:100%; border: none; pointer-events: none;"></iframe>
                            <v-img v-if="model[fieldName].template_compiled && model[fieldName].template_compiled.image && model[fieldName].template_compiled.image.url" width="100%" :src="model[fieldName].template_compiled.image.url"></v-img>
                            <embed v-if="model[fieldName].template_compiled && model[fieldName].template_compiled.pdf && model[fieldName].template_compiled.pdf.url" :src="model[fieldName].template_compiled.pdf.url + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" height="541" type="application/pdf">
                            <v-card-text v-if="model[fieldName].template_compiled && model[fieldName].template_compiled.text">
                                <div v-html="model[fieldName].template_compiled.text"></div>
                            </v-card-text>
                        </v-card>
                        <div class="itemName">
                            <b>{{ model[fieldName].name }}</b>
                            <p v-if="model[fieldName].description && model[fieldName].description.date_start">{{ parseFullDateToFr(model[fieldName].description.date_start) }}</p>
                        </div>
                    </div>
            </div>
            
        </div>
        <div v-else>
            <div :class="'create itemTemplateSelect '" v-if="!model[fieldName] || !model[fieldName].hasOwnProperty(idProperty) || !model[fieldName][idProperty]" @click="openLib">
                <div>
                    <v-chip  color="white" class="primary--text">{{ $t("noTemplateSelected") }}</v-chip>
                    <v-input v-model="model[fieldName]" type="hidden" ref="inputLayout" :rules=[validate] class=""></v-input>
                
                </div>
            </div>
            <div v-else :class="'itemTemplateSelect ' + field.libType" @click="openLib">
                <div>
                    <div v-if="!field.inCampaign">
                        <div class="itemPreview img" v-if="model[fieldName][idProperty]">
                            <v-img contains  @error="model[fieldName].image.url = default_image" :src="model[fieldName].image && model[fieldName].image.hasOwnProperty('url') && model[fieldName].image.url != '' ?  model[fieldName].image.url : default_image" :lazy-src="default_image"></v-img>
                        </div>
                        <div class="itemName">
                            <b>{{ model[fieldName].name }}</b>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="model[fieldName].template.hasOwnProperty('mp3') && model[fieldName].template.mp3" class="d-flex align-center justify-center ma-6">
                            <div  style="width:50%;">
                                <span><strong>{{$t('title')}}</strong> : {{model[fieldName].template.mp3.title}}</span>
                                <audio v-if="model[fieldName].template.mp3 && model[fieldName].template.mp3.url" controls controlsList="nodownload" :src="model[fieldName].template.mp3.url" style="width: 100%;"></audio>
                            </div>
                        </div>
                        <v-card v-else max-width="600" style="margin-left:auto; margin-right:auto;">
                            <iframe v-if="model[fieldName].template.html" scrolling="no" id="templateIframeHtml" :srcdoc="model[fieldName].template.html" @load="resizeIframe('templateIframeHtml')" style="border: none; pointer-events: none;"></iframe>
                            <v-img v-if="model[fieldName].template.image && model[fieldName].template.image.url" width="100%" :src="model[fieldName].template.image.url"></v-img>
                            <embed v-if="model[fieldName].template.pdf && model[fieldName].template.pdf.url" :src="model[fieldName].template.pdf.url + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" height="541" type="application/pdf">
                            <v-card-text  v-if="model[fieldName].template.text">
                                <div v-html="model[fieldName].template.text"></div>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </div>
            
        </div>
        <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="field.libType" @cancel="formLib = null;" :libParams="libParams" @selectModel="selectModel" :pickerForm="true" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" :isFromLibraryPage="isFromLibraryPage" />
        <LibraryPreviewComponent v-if="previewModel" :operation_id="model.operation_id ? model.operation_id : false" :inCampaign="(field && field.inCampaign) ? true : false" :fromStepper="fromStepper" :campaign="field.campaign ? field.campaign : false" :libType="field.libType" :model="previewModel" :openPreviewDialog="openPreviewDialog" @close="closePreview" :fromPresta="fromPresta"/>
        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
    </div>
</template>
<script>
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
import GenericDataService from '@/services/GenericDataService';
import getForm from "@/mixins/mixins";
import dateManipulation from '@/mixins/dateManipulation'
import validate from '../functions/validations'
import infoTooltip from '@/components/infoTooltip';

export default {
    components:{
        FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"),
        LibraryDialogComponent,
        LibraryPreviewComponent: () => import("@/components/LibraryPreviewComponent"),
        infoTooltip
    },
    mixins:[getForm, dateManipulation, validate],
    props:['fieldName', 'model', 'field', 'returnEmit', 'isFromLibraryPage', 'propertyToCheck', 'fromStepper', 'fromPresta'],
    data() {
        return {
            form:null,
            formLib:null,
            showLibraryDialog:false,
            key: Math.random(1000000),
            hover:false,
            openPreviewDialog: false,
            previewModel:false,
            idProperty: 'id',
        }
    },
    created() {
        if(this.propertyToCheck)
            this.idProperty = this.propertyToCheck;
    },
    computed: {
        libParams(){
            let params = {}
            if(this.model.operation_type_id)
                params.operation_type_id = this.model.operation_type_id

            if(this.model.operation_id)
                params.operation_id = this.model.operation_id

            return params
        },
        default_image(){
            return '/img/default_'+this.field.libType+'.jpg';
        }
    },
    methods: {
        closePreview(){
            this.openPreviewDialog = false;
            this.$emit("refreshAllTemplates");
        },
        openPreview(){
            console.log("burp")
            this.previewModel = this.model[this.fieldName]
            this.openPreviewDialog = true;
        },
        editTemplate() {
            this.getForm('/library/getForm?formName=' + this.field.libType + '&id=' + this.model[this.fieldName][this.idProperty] + '&step=["conception"]'+(this.field.campaign && this.field.campaign.id ? '&postdata[retroplanning_id]='+this.field.campaign.id : ''));
        },
        customRefresh() {
            this.$emit("refreshAllTemplates");
        },
        resizeIframe(id) {
            var iframe = document.getElementById(id);
            iframe.style.width = "100%";
            iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
            iframe.style.overflow = "hidden";
        },
        openLib(){
            let url = '/library/getFilters?libType=' + this.field.libType;

            if(this.model.operation_id)
                url += '&params[operation_id]=' + (this.model.operation_id.value ? this.model.operation_id.value : this.model.operation_id);

            if(this.model.retroplanning_id)
                url += '&params[retroplanning_id]=' + this.model.retroplanning_id;

            if(this.field.libFilters) {
                for (let filter in this.field.libFilters)
                    url += '&filters[' + filter + ']=' + encodeURIComponent(JSON.stringify(this.field.libFilters[filter]));
            } else if(this.model.rp && this.model.rp.sousType && this.model.rp.sousType == "campSMSconversational") {
                url += '&filters[filters.type]=' + encodeURIComponent(JSON.stringify(["CONVERSATIONAL"]));
            }
            
            GenericDataService.getData(url).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true
            });
        },
        selectModel(model){
            if(model.template)
                delete model.template
                
            if(this.returnEmit)
                this.$emit("selectModel", model);
            else
                this.$set(this.model, this.fieldName, model)
        }
    },
}
</script>
<style lang="scss">
.libField {
    width:300px;
    margin-bottom:10px;
    position:relative;
    .itemTemplateSelect {
        position: relative;
        height: 200px;
        width: 100%;
        margin-right:1%;
        margin-bottom:0px;
        border-radius:5px;
        border: 1px solid rgba(0, 0, 0, 0.13);
        background-color: rgba(0, 0, 0, 0.03);
        cursor: pointer;
        overflow:hidden;
        &.create {
            border: 1px solid rgba(9, 87, 125, 0.2);
            background-color: rgba(9, 87, 125, 0.1);
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
                margin-bottom:0;
                text-transform: uppercase;
                margin-top:10px;
                font-weight:bold;
            }
        }
        .itemPreview {
            height: 169px ;
            padding:30px;
            display:flex;
            justify-content:center;
            align-items:center;
            position:relative;
            overflow: hidden;
            &.email {
                padding:0;
                align-items: flex-start;
                overflow:hidden;
                embed {
                    overflow: hidden;
                }
            }
            &.MMS {
                padding:0px;
                overflow:hidden;
                img {
                    width:100%;
                    height:auto;
                }
            }
            .template-span {
                top: 0;
                position: absolute;
            }
            &.img{
                padding:0px;
            }
            .v-img{
                width:100%;
                height:100%;
            }
        }
        .itemName {
            height:30px;
            padding: 5px;
            border-top: 1px solid rgba(0, 0, 0, 0.13);
            border-bottom: 1px solid rgba(0, 0, 0, 0.13);
            background-color: white ;
        }
    }
    &.inCampaign {
        width:100%;
        .itemTemplateSelect {
            width:100%;
            min-height: 200px;
            height:auto;
            overflow: auto;
            &.VMS {
                min-height:auto;
            }
        }
    }
    &.inStepper {
        width:100%;
        .itemTemplateSelect {
            width:100%;
            height: 300px;
            overflow: hidden;
            position:relative;
            &.VMS {
                min-height:auto;
            }
            .itemName {
                position:absolute;
                bottom:-1px;
                width:100%;
                height:50px;
            }
            .chipType {
                position:absolute;
                bottom:55px;
                left:10px;
                z-index:9
            }
        }
    }
}

.cursorPointer {
    cursor:pointer;
}
    
</style>