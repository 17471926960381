<template>
  <div class="py-3 d-flex flex-wrap">
    <div v-for="(header, index) in headersInExpand" v-show="item[header.value] && item[header.value] != ''" :key="'expand_' + index" class="pa-1" style="width:50%; flex-shrink:0;">
      <b class="mr-1">{{ $t(header.value) }} :</b>
      <span v-if="header.type == 'array_phone'" :key="header.value">
        <span
          @click.stop="dialPhone(phone.number)"
          v-for="(phone, index) in item[header.value]"
          :key="index"
        >
          <v-chip
            color="primary"
            v-if="phone.type"
            x-small
            outlined
            class="type-phone mr-2"
            >{{ phone.type }}</v-chip
          >
          {{ phone.number }}
        </span>
      </span>
      <!-- si on est dans une colonne de type téléphone -->
      <span v-else-if="header.type == 'phone'" :key="header.value">
        <span @click.stop="dialPhone(item[header.value].number)">
          <v-chip
            v-if="item[header.value].type"
            color="primary"
            x-small
            outlined
            class="type-phone mr-2"
            >{{ item[header.value].type }}</v-chip
          >
          {{ item[header.value].number }}
        </span>
      </span>
      <!-- si on est dans une colonne de type marque -->
      <span v-else-if="['brand_array', 'entity_array', 'univers_array'].includes(header.type)" :key="header.value">
        <span v-for="(brand, index) in item[header.value]" :key="index">
          {{ brand.label
          }}<span v-if="index < item[header.value].length - 1">, </span>
        </span>
      </span>
      <!-- si on est dans une colonne de type Objet -->
      <span
        v-else-if="
          header.type == 'object' &&
            item[header.value] &&
            item[header.value].label
        "
        :key="header.value"
      >
        <span style="display:none;">{{ item[header.value].label }}</span>
        <v-chip
          v-if="item[header.value].label"
          color="info"
          x-small
          outlined
          class="type-phone mr-2"
        >
          {{ item[header.value].label }}
        </v-chip>
      </span>
      <!-- si on est dans une colonne de type liens -->
      <span v-else-if="header.type == 'links'" :key="header.value">
        <div v-for="(link, index) in item[header.value]" :key="index">
          <div v-if="link !== null">
            <router-link :to="link" class="filterButton">
              {{ link.title }}
            </router-link>
          </div>
        </div>
      </span>
      <!-- si on est dans une colonne de type lien -->
      <span v-else-if="header.type == 'link'" :key="header.value">
        <div v-if="item[header.value] !== null">
          <v-avatar size="40" v-if="header.value == 'user_link'" class="mr-2">
            <v-img
              :src="
                api +
                  '/user/' +
                  item[header.value].params.user_id +
                  '/getAvatar?token=' +
                  $store.state.auth.idToken
              "
            ></v-img>
          </v-avatar>
          <router-link
            v-if="
              !item[header.value].right ||
                $func.hasRight(item[header.value].right)
            "
            :to="item[header.value]"
          >
            {{ item[header.value].title }}
          </router-link>
          <span v-else>
            {{ item[header.value].title }}
          </span>
        </div>
      </span>
      <span v-else-if="header.type == 'array_link'" :key="header.value">
        <div v-if="item[header.value] !== null">
          <div
            v-for="(linkArray, index) in item[header.value]"
            :key="'link_' + index"
          >
            <span v-for="link in linkArray" :key="link.ref">
              <router-link
                v-if="!link.right || $func.hasRight(link.right)"
                :to="link"
                class="filterButton"
              >
                {{ link.title }}
              </router-link>
              <div v-else>
                {{ link.title }}
              </div>
            </span>
          </div>
        </div>
      </span>
      <span v-else-if="header.type == 'object_link'" :key="header.value">
        <div v-if="item[header.value] !== null">
          <router-link
            v-if="
              !item[header.value].right ||
                $func.hasRight(item[header.value].right)
            "
            :to="item[header.value]"
            class="filterButton"
          >
            {{ item[header.value].title }}
          </router-link>
          <div v-else>
            {{ item[header.value].title }}
          </div>
        </div>
      </span>
      <!-- si on est dans une colonne de type due -->
      <span v-else-if="header.type == 'due'" :key="header.value">
        <v-chip
          :color="
            item[header.value] >= 0
              ? item[header.value] > 86400
                ? 'success'
                : 'warning'
              : 'error'
          "
          x-small
          outlined
        >
          <strong>{{ getChipsTime(item[header.value]) }}</strong
          ><i class="ml-1">{{
            item[header.value] >= 0 ? $t("before_deadline") : $t("of_delay")
          }}</i>
        </v-chip>
      </span>
      <!-- si on est dans une colonne de type chips -->
      <span v-else-if="header.type == 'chips'" :key="header.value">
        <span style="display:none;">{{ item[header.value] }}</span>
        <v-chip
          v-if="item[header.value] && !item[header.value].label"
          color="info"
          x-small
          outlined
          class="type-phone mr-2"
        >
          {{ header["to_trad"] ? $t(item[header.value]) : item[header.value] }}
        </v-chip>
        <v-chip
          v-else
          :color="item[header.value].color ? item[header.value].color : 'info'"
          x-small
          outlined
          class="type-phone mr-2"
        >
          {{
            header["to_trad"]
              ? $t(item[header.value].label)
              : item[header.value].label
          }}
        </v-chip>
      </span>
      <span v-else-if="header.type == 'datetime'" :key="header.value">
        <span v-if="item[header.value]">{{
          parseFullDateToFr(item[header.value])
        }}</span>
      </span>
      <span v-else-if="header.type == 'date'" :key="header.value">
        <span v-if="item[header.value]">{{
          parseUsDateToFr(item[header.value].split(" ")[0])
        }}</span>
      </span>
      <span v-else-if="header.type == 'string' && header.value == 'config.main_contact'" :key="header.value">
          {{item[header.value] ? JSON.parse(item[header.value]).text : ''}}
      </span>
      <span v-else-if="header.type == 'chips_array'" :key="header.value">
        <span v-for="(chip, index) in item[header.value]" :key="index">
          <span style="display:none;">{{ chip }}</span>
          <v-chip
            v-if="chip"
            color="info"
            x-small
            outlined
            class="type-phone mr-2"
          >
            {{ header["to_trad"] ? $t(chip) : chip }}
          </v-chip>
        </span>
      </span>
      <!-- si on est dans une colonne de type json Recurrence -->
      <span v-else-if="header.type == 'recurrence'" :key="header.value">
        <v-chip
          v-if="item[header.value]"
          color="info"
          x-small
          outlined
          class="type-phone mr-2"
        >
          <span
            v-if="
              item[header.value].day &&
                !item[header.value].hasOwnProperty('month')
            "
            >{{
              $t("AllOf") +
                " " +
                item[header.value].day +
                " " +
                $t("monthLabel")
            }}</span
          >
          <span v-if="item[header.value].day && item[header.value].month">{{
            $t("AllOf") +
              " " +
              $t(item[header.value].day) +
              " " +
              $t("ofLabel") +
              " " +
              $t(item[header.value].month)
          }}</span>
          <span v-if="item[header.value].dayOfWeek">{{
            $t("AllOf") + " " + $t(item[header.value].dayOfWeek)
          }}</span>
          <span v-if="item[header.value].date">{{
            parseFullDateToFr(item[header.value].date)
          }}</span>
        </v-chip>
      </span>
      <!-- si on est dans une colonne de type chips -->
      <span
        v-else-if="
          header.type == 'object' &&
            (header.value == 'user_id' ||
              header.value == 'contact_id' ||
              header.value == 'assignment_user_id' ||
              header.value == 'contractor' ||
              header.value == 'created_by' ||
              header.value =='validated_by')
        "
        :key="header.value"
      >
        <v-avatar size="40" v-if="header.value == 'created_by' || header.value =='validated_by'" class="mr-2">
          <v-img
            :src="
              api +
                '/user/' +
                item[header.value].id +
                '/getAvatar?token=' +
                $store.state.auth.idToken
            "
          ></v-img>
        </v-avatar>
        {{
          (item[header.value].firstname
            ? item[header.value].firstname + " "
            : "") + (item[header.value].name ? item[header.value].name : "")
        }}
      </span>
      <span
        v-else-if="header.type == 'object' && header.value == 'template_id'"
        :key="header.value"
      >
        <v-chip v-if="item[header.value].id" x-small outlined color="black">
          <v-icon small class="mr-2">$paintroller_l</v-icon>
          #T{{ item[header.value].num }} - {{ item[header.value].version }}
        </v-chip>
        <v-chip v-else x-small outlined color="warning">
          <v-icon small class="mr-2">$paintroller_l</v-icon>
          {{ $t("tobedefined") }}
        </v-chip>
      </span>
      <span
        v-else-if="header.type == 'object' && header.value == 'status'"
        :key="header.value"
      >
        <v-chip
          x-small
          outlined
          :color="$func.getChipColorByStatus(item[header.value])"
          >{{ $t(item[header.value]) }}</v-chip
        >
      </span>
      <span v-else-if="header.type == 'boolean'" :key="header.value">
        <v-icon dense v-if="item[header.value] == true" color="success" text
          >$check_l</v-icon
        >
        <v-icon v-if="item[header.value] == false" dense color="error" text
          >$close</v-icon
        >
      </span>
      <span v-else-if="header.type == 'switch'" :key="header.value">
        <v-btn
          depressed
          dense
          color="success lighten-5"
          class="square"
          v-if="
            typeof item[header.value] == 'boolean'
              ? item[header.value]
              : parseInt(item[header.value])
          "
          @click.stop="
            switchData(
              {
                active: false,
                arrayIds: [item[switchIds]],
                headerValue: header.value,
              },
              item
            )
          "
          ><v-icon dense color="success" text>$check_l</v-icon></v-btn
        >
        <v-btn
          depressed
          dense
          color="error lighten-5"
          class="square"
          v-if="
            typeof item[header.value] == 'boolean'
              ? !item[header.value]
              : !parseInt(item[header.value])
          "
          @click.stop="
            switchData(
              {
                active: true,
                arrayIds: [item[switchIds]],
                headerValue: header.value,
              },
              item
            )
          "
          ><v-icon dense color="error" text>$close</v-icon></v-btn
        >
      </span>
      <span v-else-if="header.type == 'todoType'" :key="header.value">
        <v-list-item-avatar>
          <v-icon dense class="iconTodo" :color="item['icon_color']">{{
            item["icon"]
          }}</v-icon>
        </v-list-item-avatar>
        {{ header["to_trad"] ? $t(item[header.value]) : item[header.value] }}
      </span>
      <span v-else-if="header.type == 'label'" :key="header.value">
        {{
          header["to_trad"]
            ? $t(item[header.value].label)
            : item[header.value].label
        }}
      </span>
      <!-- sinon -->
      <span
        v-else-if="
          header.type == 'string' && typeof item[header.value] == 'object'
        "
        :key="header.value"
      >
        <v-chip
          x-small
          outlined
          color="primary"
          class="mr-2"
          v-for="(value, index) in item[header.value]"
          :key="index"
        >
          {{ $t(value) }}
        </v-chip>
      </span>
      <span v-else-if="header.type == 'json'" :key="header.value">
        <span v-for="(item, index) in (item[header.value])">{{item.config.name}}</span>
      </span>
      <span v-else :key="header.value">
        {{ header["to_trad"] ? $t(item[header.value]) : item[header.value] }}
      </span>
    </div>
  </div>
</template>
<script>
import getChipsTime from '@/mixins/dateManipulation';
import parseJsDateToDatetimeSql from '@/mixins/dateManipulation';
import parseDatetimeStringToDatetime from '@/mixins/dateManipulation';
import dateManipulation from '@/mixins/dateManipulation'
export default {
  name: "DatatableExpandComponent",
  props: ["headersInExpand", "item"],
  mixins:[getChipsTime, parseJsDateToDatetimeSql, parseDatetimeStringToDatetime, dateManipulation],
  data() {
    return {
      api: this.$store.state.auth.apiUrl,
    };
  }
};
</script>
<style lang="scss"></style>
