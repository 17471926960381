<template>
  <div class="col col-12 pa-0 input-form v-file-input mb-6">
    <span v-if="field.title" class="label">
        <b>{{$t(field.title)}}</b>
        <span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span>
        <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
    </span>
      
    <div class="thumbnailDirect"
      :class="!field.props.multiple && field.props.accept != 'image/*' ? 'single' : ''"
    @drop.prevent="onDrop($event)"
    @dragenter.prevent="dragover = true"
    @dragover.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    @click="selectFiles">
    <div v-if="loading" class="loaderUpload">
       <v-progress-circular
          :size="50"
          :width="7"
          color="primary"
          indeterminate
          ></v-progress-circular>
    </div>
      <span class="activeHover" v-if="!fileUploaded || (!fileUploaded.hasOwnProperty('url') && !field.props.multiple) || (fileUploaded.length == 0 && field.props.multiple)">
        <div v-if="!model[fieldName] || model[fieldName].length === 0 || !model[fieldName].hasOwnProperty('url')" class="upload">
          <v-icon>$paperclip_l</v-icon>
          <p :class="field.props.multiple ?  'mt-2 mb-0' : 'mt-0 mb-0 ml-2'" v-if="field.props.multiple">{{$t('DropYourFilesLabel')}}</p>
          <p :class="field.props.multiple ?  'mt-2 mb-0' : 'mt-0 mb-0 ml-2'" v-else>{{$t('DropYourFileLabel')}}</p>
          <p :class="field.props.multiple ?  'mb-2' : 'mb-0 ml-2 mr-2'">{{$t('or')}}</p>
          <v-btn depressed color="pink" dark small>
              <span v-if="field.props.multiple">{{$t('ChooseYourFilesLabel')}}</span>
              <span v-else>{{$t('ChooseYourFileLabel')}}</span>
          </v-btn>
          <p v-if="accept" class="mb-0 ml-3">({{$t('acceptedFormats')}} : {{accept}})</p>
          <p v-if="errorFile != ''" class="error--text mt-2"><b>{{errorFile}}</b></p> 
        </div>
      </span>
      <span v-else>
        <ul class="mt-2 listItems" v-if="field.props.multiple ">
          <li v-for="item in fileUploaded" :key="item.id" class=" itemFile" :class="field.props.multiple ?  'mb-2' : 'mb-0'">
            {{item.title}}
            <v-btn small text class="square mr-2" @click.stop="removeItem(item)"><v-icon small color="error">$close</v-icon></v-btn>
          </li>
        </ul>
        <span class="text-center" style="position:relative; display:flex; justify-content:center;"  v-else>
          <v-btn small text class="square mr-2" style="position:absolute; z-index:10; top:5px; right:5px;" @click.stop="removeItem(fileUploaded[0])"><v-icon small color="error">$trash_l</v-icon></v-btn>
          <embed v-if="model[fieldName].mime && model[fieldName].mime === 'application/pdf'" :src="model[fieldName].url + '#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" :height="window.height"/>
          <v-img v-else :src="fileUploaded.thumb + '?option=x300,y200,crop'" style="flex-grow:0" width="300" height="200" ></v-img>
        </span>
        <div v-if="field.props.multiple" style="text-align:center;">
          <v-btn depressed color="secondary" class="mb-2" small>Ajouter</v-btn>
          <p v-if="errorFile != ''" class="error--text mt-2"><b>{{errorFile}}</b></p> 
        </div>
      </span>
      <input
          ref="uploaderFilesOperation"
          class="d-none"
          type="file"
          :multiple="field.props.multiple"
          @change="onFileChange"
          :accept="accept"
      >
      <!-- <div v-if="model[fieldName] && Object.keys(model[fieldName]).length !== 0">
        <span v-if="!field.props.multiple">
          <embed v-if="model[fieldName].mime && model[fieldName].mime === 'application/pdf'" :src="model[fieldName].url + '#zoom=35'" width="300" height="200" />
          <v-img v-else-if="model[fieldName].thumb" :src="model[fieldName].thumb + '?option=x300,y200,crop'" width="300" height="200" ></v-img>
        </span>
        <span v-else>
          <ul class="mt-2 listItems">
            <li v-for="item in model[fieldName]" :key="item.id" class="mb-2">
              {{item.title}}
            </li>
          </ul>
        </span>
      </div> -->
    </div>
    <div v-if="field.specificationsText" class="specifications mt-4">
        <p v-for="(specification, index) in field.specificationsText" :key="index" class="mb-0">{{ $t(index) }} : {{ Array.isArray(specification) ? specification.join(", ") : $t(specification) }}</p>
    </div>

    <div class="mt-2 ml-4 error--text" v-if="field.errorMessage">{{$t(field.errorMessage)}}</div>
  </div>
</template>

<script>
import validate from "../functions/validations";
import GenericDataService from '@/services/GenericDataService'
import infoTooltip from '@/components/infoTooltip';

export default {
  props: ["model", "fieldName", "field"],
  components: { infoTooltip },
  mixins: [validate],
  data() {
    return {
      dragover:false,
      files: [],
      fileUploaded:[],
      loading:false,
      errorFile: "",
      accept: ".jpg, .jpeg, .gif, .png, .bmp, .ico, .svg, .webp",
      window: {
        height: 0
      },
    };
  },
  created() {
    if(this.model[this.fieldName] && typeof this.model[this.fieldName] === 'string')
        this.model[this.fieldName] = JSON.parse(this.model[this.fieldName]);

    if(this.model[this.fieldName] && (this.model[this.fieldName].length > 0 || this.model[this.fieldName].url)){
      this.fileUploaded = this.model[this.fieldName]
    }

    if (this.field.props.accept) {
        this.accept = this.field.props.accept;
    }

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  watch: {
    fileUploaded(newVal){
        this.model[this.fieldName] = this.fileUploaded
    },
    model(val){
      if(this.model[this.fieldName]){
        this.fileUploaded = this.model[this.fieldName]
      }
    }
  },
  computed: {
        mediaUrl(){
            return this.$store.state.auth.currentUser.accountParameters.mediaUrl
        },
        apiKey(){
            return this.$store.state.auth.currentUser.accountParameters.mediaApiKey
        },
    },
  methods: {
    removeItem(file){
      if(this.field.props.multiple){
        let fileIndex = this.fileUploaded.findIndex((e) => e.id == file.id)
        this.fileUploaded.splice(fileIndex, 1)
      } else {
        this.fileUploaded = {}
      }
      
      this.$refs.uploaderFilesOperation.value = ''
      setTimeout(() => {
        this.$emit('change')
      }, 150);
    },
    onFileChange(){
      if(this.$refs.uploaderFilesOperation.files.length > 0){
        this.loading = true
        this.files = [];
        this.errorFile = ""
        this.$refs.uploaderFilesOperation.files.forEach(element => {
            if (this.accept !== '*' && !this.accept.split(', ').includes('.'+element.name.split('.').pop())) {
                this.loading = false;
                this.errorFile = "Le format du fichier n'est pas pris en charge";
            } else {
                this.files.push(element)
            }
        });
        if(this.files.length > 1 && !this.field.props.multiple){
            this.files = [];
            this.loading = false
            this.errorFile = "L'upload est limité à un élément"
        } else if (this.files.length) {
            this.errorFile = ""
            this.uploadToMediatheque()
        }
      }
    },
    onDrop(event){
      this.loading = true
      this.files = [];
      this.dragover = false;
      event.dataTransfer.files.forEach(element => {
        if (this.accept !== '*' && !this.accept.split(', ').includes('.'+element.name.split('.').pop())) {
            this.loading = false;
            this.errorFile = "Le format du fichier n'est pas pris en charge";
        } else {
            this.files.push(element)
        }
      }
      );
      if(this.files.length > 1 && !this.field.props.multiple){
          this.files = [];
          this.loading = false
          this.errorFile = "L'upload est limité à un élément"
      } else if (this.files.length) {
          this.errorFile = ""
          this.uploadToMediatheque()
      }
    },
    async uploadToMediatheque(){
        const arrayBase64 = await this.fileListToBase64(this.files)
        arrayBase64.forEach((file, index) => {
          let jsonData = {
              'title': file.filename + (this.field.converter_extension ? this.field.converter_extension : ''),
              'meta': this.field.props.meta ? this.field.props.meta : {},
              'base64': file.base64,
              'av_converter': this.field.converter ? this.field.converter : null,
              'options': {
                  'crypt': this.field.props.crypt ? this.field.props.crypt : false,
                  'av_converter': 'MP4_720'
              }
          };

          let ruleError = false;
          if(this.field.fileRule){
            if(this.field.fileRule.maxSize && this.field.fileRule.maxSize < file.fileSize){
              this.loading = false
              this.errorFile = this.$t("fileMaxSizeError", {nb: this.field.fileRule.maxSize})
              ruleError = true
            }
          }

          if(!ruleError){
            GenericDataService.postData(this.mediaUrl + "upload?key=" + this.apiKey, jsonData).then((response) => {
              if(response.data.success){
                let ruleError = false;
                if(this.field.fileRule && this.field.fileRule.path){
                  let dataToCheck = this._.get(response.data.media, this.field.fileRule.path);
                  if(this.field.fileRule.minLength && this.field.fileRule.minLength > dataToCheck){
                    this.loading = false
                    this.errorFile = this.$t("fileMinLengthError", {nb : this.field.fileRule.minLength} )
                    ruleError = true
                  }
                  if(this.field.fileRule.maxLength && this.field.fileRule.maxLength < dataToCheck){
                    this.loading = false
                    this.errorFile = this.$t("fileMaxLengthError", {nb : this.field.fileRule.maxLength} )
                    ruleError = true
                  }

                }
                
                if(!ruleError){
                  if(this.field.props.multiple){
                    this.fileUploaded.push(response.data.media)
                  } else {
                    this.fileUploaded = response.data.media
                  }
                  
                  this.filtersForUpload = {};
                  this.files = [];
                  this.errorFile = ""
                  if(index == (arrayBase64.length - 1)){
                    this.loading = false
                  }
                  
                  setTimeout(() => {
                    this.$emit('change')
                  }, 150);
                }
                  
              } else {
                this.files.forEach((file) => {
                    this.files = [];
                    this.loading = false
                    this.errorFile = "Un problème est survenu"
                })
              }
            });
          }
        });
    },
    async fileListToBase64(fileList) {
        // create function which return resolved promise
        // with data:base64 string
        function getBase64(file) {
          const reader = new FileReader()
          return new Promise(resolve => {
            reader.onload = ev => {
              resolve(ev.target.result)
            }
            reader.readAsDataURL(file)
          })
        }
        // here will be array of promisified functions
        const promises = []

        // loop through fileList with for loop
        for (let i = 0; i < fileList.length; i++) {
          promises.push({"base64" : await getBase64(fileList[i]), "filename" : fileList[i].name, 'fileSize' : fileList[i].size})
        }

        // array with base64 strings
        return await Promise.all(promises)
    },
    selectFiles(){
      this.$refs.uploaderFilesOperation.click()
    },
    closeDialogMediatheque: function () {
      this.dialogMediatheque = false;
    },
    handleResize() {
      this.window.height = window.innerHeight - 250;
    },
  },
};
</script>

<style lang="scss">
.thumbnailDirect {
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  //border: 1px solid #cccccc;
  position:relative;
  .loaderUpload {
    width: 100%;
    height: 100%;
    position:absolute;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index:1000;
    background-color:rgba(255,255,255,0.7)
  }
  .activeHover {
    width: 100%;
    height: 100%;
    position:absolute;
    .upload {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f0f0f0;
      position:absolute;   
    }
  }
  .listItems {
    list-style: none;
    padding-left:0 !important;
    //border:1px solid rgba(0, 0, 0, 0.2);
    li {
      padding-left:10px;
      padding-bottom:7px;
      border-bottom:1px solid rgba(0, 0, 0, 0.2);
    }
    .itemFile {
      display:flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &.single {
    //min-height:45px;
    .activeHover {
      width: 100%;
      height: 100%;
      position:absolute;
      .upload {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #f0f0f0;
        position:relative;   
      }
    }
    .listItems {
      li {
        border-bottom:0px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
  &:hover {
    .activeHover {
      .upload {
        background: #e0e0e0;
      }
    }
  }
}

</style>