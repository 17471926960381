<template>
    <v-card rounded="lg"  class="pa-3 px-2 retroplanningContactList">
        <div v-if="loader" class="loaderUpload">
            <v-progress-circular
                :size="50"
                :width="7"
                color="primary"
                indeterminate
                ></v-progress-circular>
        </div>
        <v-card-title class="retroTitle" v-if="!limitRetro" v-show="retroplanning.config.name">
            <b>{{retroplanning.config.name}} ({{this.parseFullDateToFr(retroplanning.config.date_start).slice(0, 5)}}<span v-if="retroplanning.config.date_end"> {{$t('to')+" "+this.parseFullDateToFr(retroplanning.config.date_end).slice(0, 5)}}</span>)</b>
        </v-card-title>
        <v-card-text class="pt-3">
            <h2 v-if="limitRetro" class="mb-5" style="min-height:unset;">{{$t('recipients')}}</h2>
            <v-alert class="mb-0  mb-2" border="left" :icon="'$warning_s'" color="warning" text v-if="retroplanning.config.contact_number && retroplanning.config.contact_number > 10000 && sousType == 'campVMSout'">
                {{ $t("CampaignMustBeProgrammed24hoursBefore") }}
            </v-alert>
            <v-alert class="mb-0  mb-2" border="left" color="warning" text v-if="retroplanning.config.contact_list_hydrat && retroplanning.config.contact_list_hydrat == 0">
                {{ $t("noDestinataireSelected") }}
            </v-alert>
            <v-card color="lightgrey" class="no-shadow mb-2" v-else v-for="contactlist in retroplanning.config.contact_list_hydrat" :key="'contactlist_'+retroplanning.id+'_'+contactlist.id" @click="openStats(contactlist)" :style="retroplanning.status === 'COMPLETED' ? 'cursor: default;' : ''">
                <v-card-title class="justify-space-between">
                    <div class="contactListTitle">
                        <b>{{contactlist.name}} <v-chip :color="contactlist.type == 'STATIC' ? 'primary' : 'pink'" class="ml-3" dark small>{{$t(contactlist.type)}}</v-chip></b>
                        <div>{{contactlist.entity_name}}</div> 
                    </div>
                    <span class="d-flex align-center" v-if="retroplanning.status !== 'COMPLETED'">
                        <div class="contactListContactCount">
                            <b>{{contactlist.contact_count}}</b> Contacts 
                        </div>
                        <v-btn text class="square ml-2" @click.stop="$emit('removeListFromLP', contactlist.id, retroplanning)" v-if="!fromPresta && (!retroplanning.status || (retroplanning.status && !['PROGRAMMED', 'COMPLETED'].includes(retroplanning.status))) && $func.hasRight('contactlist/delete')">
                            <v-icon small color="black" >$close</v-icon>
                        </v-btn>
                    </span>
                </v-card-title>
            </v-card>
        </v-card-text>
        <v-card-actions class="px-4 pt-0" v-if="retroplanning.status !== 'COMPLETED'">
            <v-btn class="bg-gradient" depressed v-if="!fromPresta && (!retroplanning.status || (retroplanning.status && !['PROGRAMMED', 'COMPLETED'].includes(retroplanning.status)) || (retroplanning.status == 'PROGRAMMED' && retroplanning.config.type == 'CAMP_CALL')) && $func.hasRight('contactlist/add')" @click="$emit('addList', retroplanning.config.contact_list)">{{$t('addlist')}}</v-btn>
            <v-spacer></v-spacer>
            <p class="mb-0 d-flex align-center" v-if="retroplanning.config && retroplanning.config.hasOwnProperty('contact_number') && retroplanning.config.hasOwnProperty('contact_number_total')">
                <b>{{$t('estimatedContacts')}}</b> 
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color='primary' class="mx-2" v-bind="attrs" v-on="on">$circleinfo_d</v-icon> 
                    </template>
                    <span v-html="$t('contactListEstimatedCountLabel')"></span>
                </v-tooltip>
                <b style="font-size:20px">{{retroplanning.config.contact_number}} / {{retroplanning.config.contact_number_total}}</b>
            </p>
        </v-card-actions>

        <dialogStatsContactComponent v-if="openDialogStats" @closeStatsContactDialog="openDialogStats = false" :openDialogStats="openDialogStats" :id="contactlistId" :retroplanning_id="retroplanning.id" :idType="'contactlist_id'" />
    </v-card>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
import getForm from "@/mixins/mixins";
import dialogStatsContactComponent from '@/components/dialogs/dialogStatsContactComponent';

export default {
    name:"retroplanningContactListComponent",
    props: ['retroplanning', 'loader', 'limitRetro', 'sousType', 'fromPresta'],
    components: {dialogStatsContactComponent},
    mixins: [getForm, dateManipulation],
    data() {
        return {
            openDialogStats: false,
            contactlistId: null,
        }
    },
    watch: {
        loader(val){
        }
    },
    methods: {
        openStats(item) {
            if(!this.fromPresta && this.retroplanning.status !== 'COMPLETED') {
                this.contactlistId = item.id;
                this.openDialogStats = true;
            }
        }
    }
}
</script>
<style lang="scss">
    .retroplanningContactList {
        .retroTitle, .contactListTitle, .contactListContactCount {
            b {
                font-weight: 900;
            }
        }
    }
    .loaderUpload {
            width: 100%;
            height: 100%;
            position:absolute;
            display:flex;
            justify-content: center;
            align-items: center;
            z-index:1000;
            top:0;
            left:0;
            background-color:rgba(255,255,255,0.7)
        }
</style>