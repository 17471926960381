<template>
    <div class="col col-12 pa-0 input-form v-match-fields">
        <span class="label pb-5">
            <b>{{ $t(field.title) }}</b>
            <span style="color:red">&nbsp;*</span>
            <infoTooltip v-if="field.tooltip" :tooltip="field.tooltip" />
        </span>

        <div v-if="Object.values(model[fieldName]).length > 0">
            <v-row  v-for="(linkField, index) in model[fieldName]" :key="index">
                <v-col cols="1">
                    <span class="label mt-3"><b>{{ field.formFields[index] }}</b><span style="color:red">&nbsp;*</span></span>
                </v-col>
                <v-col>
                    <v-select
                    :placeholder="$t('selectLexxyField')"
                    dense
                    flat
                    solo
                    clearable
                    :items="getFilteredLexxyFields(model[fieldName][index])"
                    v-model="model[fieldName][index]"
                    :rules=[required(model[fieldName][index])]
                    @change="$forceUpdate()"></v-select>
                </v-col>
            </v-row>
        </div>

        <div v-else>
            <v-alert class="mb-0" border="left" color="info" text dense>
                {{ $t("emptyFieldInfoMessage") }}
            </v-alert>
            <v-text-field :rules="[validate]" class="hiddenField pt-0 pl-3"></v-text-field>
        </div>

        <div class="py-5">
            <v-btn small depressed color="pink" dark @click="openLinkFieldsModal">
                {{ $t("linknewfield") }}
            </v-btn>
        </div>

        <VMatchFieldModal ref="linkFieldsModal" />
    </div>
</template>

<script>
import validate from '../../../functions/validations'
import required from '../../../functions/validations'
import VMatchFieldModal from "@/components/forms/inputs/custom/forms/v-match-fields-modal";
import infoTooltip from '@/components/infoTooltip';
import axiosHttp from "@/http-common";

export default {
    name: "VMatchFields",
    components: { VMatchFieldModal, infoTooltip },
    mixins: [validate, required],
    props: ["model", "fieldName", "field"],
    data() {
        return {
            items: [],
        };
    },
    created() {
        if (!this.model[this.fieldName] || Array.isArray(this.model[this.fieldName])) {
            this.$set(this.model, this.fieldName, {});
        }
        this.getLexxyFields();
    },
    methods: {
        async openLinkFieldsModal() {
            let model = {};

            for (const property in this.field.formFields)
                model[property] = this.model[this.fieldName].hasOwnProperty(property) ? true : false;

            if(await this.$refs.linkFieldsModal.open(model, this.field)) {
                for (const property in model) {
                    if(model[property]) {
                        if(!this.model[this.fieldName].hasOwnProperty(property))
                            this.$set(this.model[this.fieldName], property, null);
                    } else {
                        this.$delete(this.model[this.fieldName], property);
                    }
                }
            }
        },
        getLexxyFields() {
            this.items = [];

            if(this.field.selectDataUrl) {
                axiosHttp.http.post(this.field.selectDataUrl.replace(/{{(.*?)}}/g, this.replaceFilter)).then((response) => {
                    this.items = response.data.data;

                    if(this.items.length === 0) {
                        this.$set(this.model, this.fieldName, {});
                    } else {
                        for (const [key, value] of Object.entries(this.model[this.fieldName])) {
                            if(!this.items.includes(value)) {
                                this.$delete(this.model[this.fieldName], key);
                            }
                        }
                    }
                });
            } else {
                this.items = this.field.lexxyFields;
            }
        },
        getFilteredLexxyFields(value) {
            let values = [];

            this.items.forEach(element => {
                if(!Object.values(this.model[this.fieldName]).includes(element) || value === element || element === 'details.comment')
                    values.push({"value": element, "text": this.$t((this.fieldName === 'config.matchFields' ? 'contact' : 'demand') + element)});
            });

            return values;
        },
        // Permet de remplacer les filtres dans l'URL Ajax par les valeurs du model
        replaceFilter(match, fieldName) {
            if(this.field.searchInObject) {
                let searchKeys = fieldName.split('.');
                if(searchKeys.length === 2) // J'ai pas trouvé mieux ^^
                return (this.model[searchKeys[0]] && this.model[searchKeys[0]][searchKeys[1]]) ? this.model[searchKeys[0]][searchKeys[1]] :( this.model[searchKeys[0]] ? this.model[searchKeys[0]] : '0');
            } else if(this.model[fieldName]) {
                return this.model[fieldName];
            }
            return '0';  // Attention problème si id = 0
        },
    }
};
</script>

<style lang="scss">
    .v-match-fields {
        .hiddenField {
            .v-input__slot {
                display: none;
            }
        }
    }
</style>