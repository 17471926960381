<template>
    <v-card class="pa-0" >
        <v-card-text @mouseover.stop="hover = true" @mouseleave.stop="hover = false" class="pa-0">
            <div v-if="hover" :id="'thumbHover_'+model.id" class="hoverThumb d-flex align-center justify-center">
                <!-- <v-btn v-if="from === 'customLibrarySelect'" depressed color="pink" dark class="mb-4" @click="$emit('unselectModel', model)">{{ $t('unselect_model') }}</v-btn> -->
                <v-btn v-if="(isFromPopup || (!isFromPopup && libType !== 'ENVELOPE')) && model.draft == '0'" small :color="'pink'" dark :class="' mb-4 mr-2'" @click="$emit('selectModel', model)">{{ $t('useModel') }}</v-btn>
                
                <v-tooltip v-if="(isFromPopup || (!isFromPopup && libType !== 'ENVELOPE')) && model.draft == '1'" top>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-btn small :color="'grey'" dark :class="'disabled mb-4 mr-2'" @click="$emit('selectModel', model)">{{ $t('useModel') }}</v-btn>
                        </span>
                    </template>
                    <span>{{$t('modelisdraft')}}</span>
                </v-tooltip>
                <v-btn @click="$emit('openPreview', model)" small color="primary" dark class="mb-4 mr-2" v-if="isFromPopup">{{ $t('preview') }}</v-btn>
                <v-menu v-if="!isFromPopup" :open-on-hover="false" :attach="'#thumbHover_'+model.id" offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small v-bind="attrs" v-on="on" class="mb-4" color="primary"  >
                            {{$t('actions')}}
                            <v-icon small class="ml-2">$down_l</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item link dense @click="$emit('openPreview', model)">
                          <v-list-item-title>{{ $t("preview") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link dense v-if="!model.hasOwnProperty('isParent') || model.isParent == '0'" @click="$emit('editModel', model.id)">
                          <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="['EMAIL', 'SMS', 'MMS', 'VMS', 'MAILING', 'ENVELOPE', 'TEL'].includes(libType) && model.childs_are_updated == 0" link dense @click="$emit('resetChildsModel', model.id)">
                          <v-list-item-title>{{ $t("resetChilds") }}</v-list-item-title>
                        </v-list-item>
                            <v-list-item v-if="['EMAIL', 'SMS', 'MMS', 'VMS'].includes(libType)" link dense @click="$emit('sendTestModel', model.id)">
                          <v-list-item-title>{{ $t("sendTest") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="['EMAIL'].includes(libType) && model.template && model.template.html" link dense @click="downloadEmail(model.name, model.template.html)">
                          <v-list-item-title>{{ $t("download_model") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="['VMS'].includes(libType) && model.template && model.template.mp3 && model.template.mp3.url" link dense @click="downloadItem(model.template.mp3.url)">
                          <v-list-item-title>{{ $t("download_model") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link dense @click="$emit('duplicateModel', model.id)">
                          <v-list-item-title>{{ $t("duplicate") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link dense @click="$emit('deleteModel', model.id)">
                          <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <v-img class="thumbImage" height="190" contains @error="model.image.thumb = default_image" shaped :src="model.image && model.image.hasOwnProperty('thumb') &&  model.image.thumb != '' ? model.image.thumb : default_image" :lazy-src="default_image"></v-img>
            <div class="thumbDesc pt-2 pl-1" style="overflow:hidden;">
                <div class="d-flex align-center">
                    <v-badge class="mt-1 ml-2 mr-3" :color="model.draft == 1 ? 'grey' : 'success'" dot></v-badge>
                    <p v-if="model.name" class="mb-0"><b>{{ model.name.slice(0, 34)+(model.name.length > 34 ? "..." : "") }}</b></p>
                </div>
                <p v-if="model.description" class="mb-0 px-2">{{ strip(model.description) }}</p>
            </div>
        </v-card-text>
    </v-card>
   
</template>

<script>
import dateManipulation from '@/mixins/dateManipulation'
import getForm from "@/mixins/mixins"

export default {
name: "CLibMin",
props: ["model", "from", "formModel", "specData", "isFromPopup", "libType"],
mixins: [dateManipulation, getForm],
components: {
},
data() {
    return {
        form: null,
        publicPath: process.env.BASE_URL, 
        hover:false, 
    };
},
computed: {
    default_image(){
        return this.publicPath+'img/default_'+this.libType+'.jpg'
    }
},
methods: {
    strip(html){
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent.slice(0, 80)+(doc.body.textContent.length > 80 ? "..." : "") || "";
    },
    imageUrlAlt(event) {
        event.target.src = this.default_car
    },
    downloadItem(url){
        window.open(url)
    },
    downloadEmail(filename, text) {
        const el = document.createElement('a');
        el.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        el.setAttribute('download', filename + '.html');
        el.style.display = 'none';
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
    }
},
}
</script>
<style lang="scss">
.thumbImage {
    border-radius:5px 5px 0 0;
}
.thumbDesc {
    height: 70px;
}
.hoverThumb {
    position:absolute;
    z-index:1;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(255,255,255,0.6);
    .v-btn__content {
        font-size:14px !important;
    }
    .disabled {
        pointer-events: none;
    }
}


.cardOpportunity {
    overflow:hidden;
    h1 {
        line-height: 32px !important;
    }
    h2 {
        border-bottom:0;
        text-transform: none;
        height:auto;
        min-height:unset;
        font-size:22px;
    }
    h4 {
        border-bottom:0;
        text-transform: none;
        height:auto;
        min-height:unset;
        margin-bottom : 5px;
    }
    ul {
        padding-left:0;
        list-style:none;
        li{
            margin-bottom:5px;
        }
    }
    .proposalImg {
        border-radius:10px;
        overflow:hidden;
    }
    .reduction {
        position:absolute;
        border-radius:10px;
        top:-10px;
        right:10px;
        h4 {
            font-size: 18px;
            font-weight:normal !important;
            height:auto; 
            min-height:unset; 
        }
    }
    .input-form .v-input__slot {
        background-color: #FFF !important;
    }
}
</style>
